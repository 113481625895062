import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import { Link, useHistory } from 'react-router-dom';

import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";

const Header = ({ t }: { t: TFunction }) => {
// Inside your component
  const [visible, setVisibility] = useState(false);
  const history = useHistory();

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      if (!element){
        history.push("/"); // Use `history.push` to navigate
        return;
      }
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    const handleTryClick = () => {
      window.location.href = "https://t.me/spain_school_informer_bot";
    };
    return (
      <>
        <CustomNavLinkSmall onClick={() => scrollTo("features")}>
          <Span>{t("Features")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall as={Link} to="/terms">
          <Span>{t("Terms of use")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "200px" }}
          onClick={() => scrollTo("contact")}
        >
          <Span>
            <Button onClick={handleTryClick}>{t("Try it now")}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <img width="53px" height="53px" src="img/icons/icon_circle.png" alt=""/>
            <SvgIcon src="logo.svg" width="101px" height="64px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={toggleButton}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
