import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationEn from "./locales/en/translation.json";
import translationRu from "./locales/ru/translation.json"; // Assuming you have Russian translations
import translationUa from "./locales/ua/translation.json"; // Add Ukrainian translations

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,
    lng: "ua", // Set the default language to Ukrainian
    fallbackLng: "ru", // Fallback language is English

    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },

    resources: {
      en: {
        translations: translationEn,
      },
      ru: {
        translations: translationRu, // Use Russian translations
      },
      ua: {
        translations: translationUa, // Add Ukrainian translations here
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n;
